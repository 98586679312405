import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {deleteProjectComment} from 'services/commentsService';
import {getSingleProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {setSingleProjectAC} from 'store/project/setSingleProject/actionCreator';
import {IDeleteComment} from 'typescript/interfaces/ICommentsActions';
import {SingleProject} from 'typescript/models/SingleProject';

import {unsetDeletedComment} from './actionCreator';

export function* deleteComment(action: IDeleteComment) {
  const {projectId, commentId} = action.payload;

  try {
    yield call(deleteProjectComment, projectId, commentId);
    const projectResponse: AxiosResponse<SingleProject> = yield call(getSingleProject, projectId);

    yield put(unsetDeletedComment(commentId));
    yield put(setSingleProjectAC(projectResponse.data));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
