import React, {createContext, useEffect, useMemo, useState} from 'react';

import {useGlobalState} from 'hooks/useGlobalState';
import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {useSockJsWs} from 'modules/websocket/hooks/useSockJsWs';
import {getNotifications} from 'services/notificationService';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {NotificationContent, NotificationCount} from 'typescript/models/Notifications';
import {NotificationsMessage} from 'typescript/models/NotificationsMessage';

interface NotificationsContextType {
  notifications: NotificationContent[];
  notificationCount: NotificationCount;
  showAll: boolean;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  readMore: boolean;
  setReadMore: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationsContext = createContext<NotificationsContextType | null>(null);

export const NotificationsProvider = ({children}: {children: React.ReactNode}) => {
  const dispatch = useSetGlobalState();
  const {user: currentUser} = useGlobalState((state) => state.auth);
  const [notifications, setNotifications] = useState<NotificationContent[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const {connect, disconnect} = useSockJsWs();

  const counts: NotificationCount = useMemo(() => {
    return {
      ALL: notifications.length,
      UNREAD: notifications.filter((n) => !n.read).length,
      READ: notifications.filter((n) => n.read).length,
    };
  }, [notifications]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    connect({
      data: {Authorization: token},
      callback: (client) => {
        client.subscribe(`/user/${currentUser?.email}/queue/notifications`, (msg) => {
          try {
            const data = JSON.parse(msg.body) as NotificationsMessage;
            setNotifications(data.content);
          } catch (error) {
            dispatch(setGlobalNotification({variant: 'error', message: 'notifications.connectionFailed'}));
          }
        });

        getNotifications({order: 'DESC', sort: 'time'});
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.email]);

  return (
    <NotificationsContext.Provider
      value={{notifications, notificationCount: counts, showAll, setShowAll, readMore, setReadMore}}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
