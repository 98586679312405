import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {updateProject} from 'services/projectService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {setGlobalNotification} from 'store/app/setGlobalNotification/actionCreator';
import {IUpdateProject, UpdateSingleProject} from 'typescript/interfaces/IProjectActions';
import {SingleProject} from 'typescript/models/SingleProject';
import {prepareCreateProjectDTO} from 'utils/project/prepareCreateProjectDTO';

import {ProjectActions} from '../actions';
import {setUpdatedProjectACS} from './actionCreator';
import {setSingleProjectAC} from '../setSingleProject/actionCreator';

export function* updateProjectSaga(action: IUpdateProject) {
  try {
    const project = action.payload;
    const dto = prepareCreateProjectDTO(project);

    const updateResponse: AxiosResponse<SingleProject> = yield call(updateProject, project.id, dto);
    yield put(setUpdatedProjectACS(updateResponse.data));
  } catch (error) {
    yield call(handleErrorsSaga, error);
    yield put(setGlobalNotification({variant: 'error', message: 'project.updateError'}));
    throw error;
  }
}

export function* updateSingleProjectSaga(action: UpdateSingleProject) {
  try {
    yield call(updateProjectSaga, {type: ProjectActions.UPDATE_PROJECT, payload: action.payload});

    yield put(setSingleProjectAC(action.payload));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
