import SockJS from 'sockjs-client';

import {useStompWs} from './useStompWs';

export const useSockJsWs = () => {
  return useStompWs({
    socketFactory: () => {
      return new SockJS(`${process.env.REACT_APP_API_URL}/ws`);
    },
  });
};
