import {ISetUpdatedProject, IUpdateProject} from 'typescript/interfaces/IProjectActions';
import {SingleProject} from 'typescript/models/SingleProject';

import {ProjectActions} from '../actions';

export function updateProjectACS(payload: SingleProject): IUpdateProject {
  return {
    type: ProjectActions.UPDATE_PROJECT,
    payload,
  };
}

export function setUpdatedProjectACS(payload: SingleProject): ISetUpdatedProject {
  return {
    type: ProjectActions.SET_UPDATED_PROJECT,
    payload,
  };
}

export function updateSingleProjectACS(payload: SingleProject) {
  return {
    type: ProjectActions.UPDATE_SINGLE_PROJECT,
    payload,
  };
}
