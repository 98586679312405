export enum ProjectActions {
  IS_NEW_PROJECT = 'IS_NEW_PROJECT',
  GET_PROJECTS = 'GET_PROJECTS',
  SET_PROJECTS = 'SET_PROJECTS',
  SET_LOADING = 'SET_LOADING',
  SET_SINGLE_PROJECT = 'SET_SINGLE_PROJECT',
  CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  SET_UPDATED_PROJECT = 'SET_UPDATED_PROJECT',
  SET_CALENDAR_PREVIEW_PROJECT = 'SET_CALENDAR_PREVIEW_PROJECT',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  ADD_REMAINDER = 'ADD_REMAINDER',
  GET_REMAINDERS = 'GET_REMAINDERS',
  SET_REMAINDERS = 'SET_REMAINDERS',
  SET_REMAINDERS_LIST = 'SET_REMAINDERS_LIST',
  DELETE_REMAINDER = 'DELETE_REMAINDER',
  SET_CHECKED_PROJECTS = 'SET_CHECKED_PROJECTS',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_PROJECT_FILTERS = 'SET_PROJECT_FILTERS',
  SET_ARCHIVE_PAGINATION = 'SET_ARCHIVE_PAGINATION',
  ADD_FAVORITE_PROJECT = 'ADD_FAVORITE_PROJECT',
  REMOVE_FAVORITE_PROJECT = 'REMOVE_FAVORITE_PROJECT',
  SET_FAVORITE_PROJECT = 'SET_FAVORITE_PROJECT',
  UNSET_FAVORITE_PROJECT = 'UNSET_FAVORITE_PROJECT',
  SET_SELECTED_ORGANIZER = 'SET_SELECTED_ORGANIZER',
  SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT',
  ADD_REMOVE_ORGANIZER = 'ADD_REMOVE_ORGANIZER',
  ADD_REMOVE_TAG = 'ADD_REMOVE_TAG',
  ADD_REMOVE_MEMBER = 'ADD_REMOVE_MEMBER',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  SEARCH_PROJECT = 'SEARCH_PROJECT',
  SET_CHECKED_PROJECT_TAGS = 'SET_CHECKED_PROJECT_TAGS',
  GET_ACTIVITY_LOGS = 'GET_ACTIVITY_LOGS',
  SET_ACTIVITY_LOGS = 'SET_ACTIVITY_LOGS',
  SET_REMAINDER_TYPE = 'SET_REMAINDER_TYPE',
  SET_IMAGE_PREVIEW = 'SET_IMAGE_PREVIEW',
  UPDATE_SINGLE_PROJECT = 'UPDATE_SINGLE_PROJECT',
}
