import {takeLatest} from '@redux-saga/core/effects';

import {ProjectActions} from './actions';
import {addRemoveMembersSaga} from './addRemoveMembers/saga';
import {addRemoveOrganizersSaga} from './addRemoveOrganizers/saga';
import {addRemoveTagsSaga} from './addRemoveTag/saga';
import {archiveProjectSaga} from './archiveProject/saga';
import {createNewProjectSaga} from './createNewProject/saga';
import {deleteProjectSaga} from './deleteProject/saga';
import {addFavoriteProject, removeFavoriteProject} from './favorites/saga';
import {getActivityLogsSaga} from './getActivityLog/saga';
import {getProjectsSaga} from './getProjects/saga';
import {addRemainderSaga} from './Remainders/addRemainder/saga';
import {deleteRemainderSaga} from './Remainders/DeleteRemainder/saga';
import {getRemaindersListSaga} from './Remainders/getRemainders/saga';
import {searchProjectSaga} from './searchProjects/saga';
import {updateProjectSaga, updateSingleProjectSaga} from './updateProject/saga';

export default function* projectSaga() {
  yield takeLatest(ProjectActions.GET_PROJECTS, getProjectsSaga);
  yield takeLatest(ProjectActions.CREATE_NEW_PROJECT, createNewProjectSaga);
  yield takeLatest(ProjectActions.DELETE_PROJECT, deleteProjectSaga);
  yield takeLatest(ProjectActions.UPDATE_PROJECT, updateProjectSaga);
  yield takeLatest(ProjectActions.ADD_REMAINDER, addRemainderSaga);
  yield takeLatest(ProjectActions.GET_REMAINDERS, getRemaindersListSaga);
  yield takeLatest(ProjectActions.DELETE_REMAINDER, deleteRemainderSaga);
  yield takeLatest(ProjectActions.ADD_FAVORITE_PROJECT, addFavoriteProject);
  yield takeLatest(ProjectActions.REMOVE_FAVORITE_PROJECT, removeFavoriteProject);
  yield takeLatest(ProjectActions.ADD_REMOVE_TAG, addRemoveTagsSaga);
  yield takeLatest(ProjectActions.ADD_REMOVE_MEMBER, addRemoveMembersSaga);
  yield takeLatest(ProjectActions.ADD_REMOVE_ORGANIZER, addRemoveOrganizersSaga);
  yield takeLatest(ProjectActions.ARCHIVE_PROJECT, archiveProjectSaga);
  yield takeLatest(ProjectActions.SEARCH_PROJECT, searchProjectSaga);
  yield takeLatest(ProjectActions.GET_ACTIVITY_LOGS, getActivityLogsSaga);
  yield takeLatest(ProjectActions.UPDATE_SINGLE_PROJECT, updateSingleProjectSaga);
}
