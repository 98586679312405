import {useEffect} from 'react';

import {useSetGlobalState} from 'hooks/useSetGlobalState';
import {checkForUsersACS} from 'store/auth/checkForUser/actionCreators';
import {setUserAC} from 'store/auth/setUser/actionCreator';
import {User} from 'typescript/models/User';

interface IProps {
  children: React.ReactNode;
}

export default function AppSetup({children}: IProps) {
  const dispatch = useSetGlobalState();

  useEffect(() => {
    dispatch(checkForUsersACS());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const stringifiedUser = localStorage.getItem('user');
    if (stringifiedUser) {
      const user: User = JSON.parse(stringifiedUser);

      dispatch(setUserAC(user));
    }
  }, [dispatch]);

  return <>{children}</>;
}
