import {useState} from 'react';

import {CompatClient, Stomp} from '@stomp/stompjs';

interface UseStompWsArgs {
  socketFactory: () => WebSocket;
}

interface ConnectArgs {
  data: Record<string, string>;
  callback?: (client: CompatClient) => void;
}
export const useStompWs = ({socketFactory}: UseStompWsArgs) => {
  const [client, setClient] = useState<CompatClient | null>(null);

  const connect = ({data, callback = () => {}}: ConnectArgs) => {
    const stompClient = Stomp.over(socketFactory);

    stompClient.connect(data, () => {
      setClient(stompClient);
      callback(stompClient);
    });
  };

  const disconnect = () => {
    if (client && client.connected) {
      client.disconnect();
      setClient(null);
    }
  };

  const getClient = () => client;

  return {
    connect,
    disconnect,
    getClient,
  };
};
