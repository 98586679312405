import {CreateReminderDto} from 'typescript/dto/RemainderDTO';
import {Remainder} from 'typescript/models/Remainder';

import {ProjectActions} from '../../actions';

export const addRemaindersACS = (payload: CreateReminderDto) => {
  return {type: ProjectActions.ADD_REMAINDER, payload};
};

export const setRemainderRedux = (payload: Remainder) => {
  return {type: ProjectActions.SET_REMAINDERS, payload};
};
