import {AxiosResponse} from 'axios';
import {call, put} from 'redux-saga/effects';
import {uploadProjectDocument} from 'services/documentsService';
import {handleErrorsSaga} from 'store/app/handleErrors/saga';
import {IUploadDocument} from 'typescript/interfaces/IDocumentsActions';
import {Document} from 'typescript/models/Document';

import {setUploadedDocument, setUploadedFrom} from './actionCreator';

export function* uploadDocument({payload}: IUploadDocument) {
  const {from} = payload;

  try {
    const documentResponse: AxiosResponse<Document> = yield call(uploadProjectDocument, payload);

    yield put(setUploadedDocument(documentResponse.data));

    if (from) yield put(setUploadedFrom(from));
  } catch (error) {
    yield call(handleErrorsSaga, error);
  }
}
